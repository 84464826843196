import React, { useEffect, useRef, useState } from 'react';
import './VisitorPage.scss';
import CardLayout from '../common/CardLayout';
import { Trans, useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { Wifi } from '../../icons';
import mustache from 'mustache';
import SearchUsers from '../common/SearchUsers';
import ThanksCard from '../common/ThanksCard';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { VisitorsClient } from '../../services/http/apis/visitorsClient';
import SignatureCard from '../common/SignatureCard';
import { format, isToday } from 'date-fns';

const validationSchema = yup.object({
  firstName: yup.string().trim().required(),
  lastName: yup.string().trim().required(),
  email: yup.string().email().trim().required(),
  hosts: yup.string().trim().required(),
  companyName: yup.string().trim().required(),
  office: yup.string().trim().required(),
  visitorType: yup.string().trim().required(),
  visitorPurpose: yup.string().trim().required(),
  legalDocumentsRequired: yup.bool(),
});

const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  hosts: '',
  companyName: '',
  office: '',
  visitorType: '',
  visitorPurpose: '',
  legalDocumentsRequired: true,
};

const VisitorPage = ({ users, settingsWifi, useCamera }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const visitorsClient = new VisitorsClient();
  const [step, setStep] = useState(0);
  const [valueUser, setValueUser] = useState(null);
  const [transitionClass, setTransitionClass] = useState('right-to-left');
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState(null);
  const [offices, setOffices] = useState([]);
  const [officeId, setOfficeId] = useState(localStorage.getItem('GUEST::OFFICE_ID'));
  const [typesList, setTypesList] = useState([]);
  const [purposeList, setPurposeList] = useState([]);
  const [signImageDataUrl, setSignImageDataUrl] = useState(null);
  const [hostsList, setHostsList] = useState([]);
  const [isDisabledSignButton, setDisabledSignButton] = useState(true);
  const [visitorLog, setVisitorLog] = useState(null);
  const [documentContent, setDocumentContent] = useState({
    text: '',
    title: '',
  });
  const [documentForSign, setDocumentForSign] = useState(null);
  const [isEditedFields, setEditedFields] = useState(false);

  useEffect(() => {
    try {
      visitorsClient.getOffices().then((res) => {
        setOffices(res.offices);
        const currentOfficeId = officeId || res.offices[0]._id;
        visitorsClient.getSettingsVisitors(currentOfficeId).then(({ items }) => {
          setTypesList(items.filter((el) => el.kind === 'type' && el.office === currentOfficeId && el.showOnKiosk));
          setPurposeList(
            items.filter((el) => el.kind === 'purpose' && el.office === currentOfficeId && el.showOnKiosk),
          );
        });
      });
    } catch (err) {
      console.error(err);
    }
  }, []);

  useEffect(() => {
    if (officeId) {
      formik.setFieldValue('office', officeId);
    } else if (offices && offices.length && !officeId) {
      setOfficeId(offices[0]._id);
      formik.setFieldValue('office', offices[0]._id);
    }
  }, [offices]);

  const nextCard = () => {
    setTransitionClass('right-to-left');
    if (step === 0) {
      checkVisitorData(formik.values.email);
    }
    if (step === 4) {
      formik.submitForm();
      getDocumentForSign();
    }
    if (step === 5 && !documentForSign) {
      setStep(8);
      return;
    }
    if (step === 7) {
      saveLegalDocument();
    }
    if (step === 8) {
      navigate('/welcome');
    }
    setStep(step + 1);
  };

  const saveLegalDocument = async () => {
    const params = {
      visitorLog: visitorLog.id,
      revision: documentForSign.document.revision,
      signatureImg: signImageDataUrl,
      data: {
        NAME: `${visitorLog.visitor.firstName} ${visitorLog.visitor.lastName}`,
        DATE: format(new Date(), 'd MMM yyyy'),
      },
    };
    try {
      await visitorsClient.signVisitorsLegalDoc(documentForSign.documentUuid, params);
    } catch (err) {
      console.error(err);
    }
  };

  const checkVisitorData = (email) => {
    const params = {
      search: encodeURIComponent(email),
      offices: [officeId],
    };
    visitorsClient.getVisitors(params).then((res) => {
      return res.items.map((el) => {
        if (isToday(el.visitTime)) {
          getHostsData(el.hosts);
          setVisitorLog(el);
          formik.setValues({
            firstName: el.visitor.firstName,
            lastName: el.visitor.lastName,
            email: el.visitor.email,
            hosts: el.hosts[0].id,
            companyName: el.companyName,
            office: el.office,
            visitorType: el.visitorType?.id,
            visitorPurpose: el.visitorPurpose?.id,
            legalDocumentsRequired: el.legalDocumentsRequired,
          });
        }
      });
    });
  };

  const getHostsData = (hosts) => {
    setValueUser(`${hosts[0].firstName} ${hosts[0].lastName}`);
    if (hosts.length) {
      let hostsName = [];
      users.forEach((user) => {
        const findHost = hosts.find((host) => host.id === user._id);
        if (findHost) {
          hostsName.push(user);
        }
      });
      setHostsList(hostsName);
    }
  };

  const getDocumentForSign = () => {
    const tags = [
      {
        example: `${formik.values.firstName} ${formik.values.lastName}`,
        key: 'NAME',
        title: 'Name Visitor',
        value: '{{NAME}}',
      },
      {
        example: format(new Date(), 'd MMM yyyy'),
        key: 'DATE',
        title: 'Date',
        value: '{{DATE}}',
      },
    ];
    if (documentForSign?.document?.contents) {
      const keys = {};
      tags.forEach((tag) => {
        keys[tag.key] = tag.example || '';
      });
      const contents = mustache.render(documentForSign?.document?.contents, keys, {}, { escape: (text) => text });
      setDocumentContent({
        title: documentForSign.document.title,
        text: contents,
      });
    }
  };

  useEffect(() => {
    if (documentForSign) {
      getDocumentForSign();
    }
  }, [documentForSign]);

  const handelBackButton = () => {
    setTransitionClass('left-to-right');
    if (step === 0) navigate('/welcome');
    setStep(step - 1);
  };

  const formik = useFormik({
    validationSchema,
    initialValues,
    onSubmit: async (values) => {
      setLoading(true);
      const params = {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        companyName: values.companyName,
        hosts: [
          {
            id: values.hosts,
          },
        ],
        office: values.office,
        visitorType: values.visitorType,
        visitorPurpose: values.visitorPurpose,
        visitTime: new Date().toISOString(),
        legalDocumentsRequired: values.legalDocumentsRequired,
        sendEmailInvite: false,
      };
      let resultVisitorData = null;
      if (visitorLog && isEditedFields) {
        try {
          await visitorsClient
            .editVisitor(visitorLog.id, params)
            .then((res) => {
              setApiError(null);
              resultVisitorData = res;
              setVisitorLog(res);
            })
            .catch((err) => {
              setApiError(err.data.message);
            });
        } catch (err) {
          console.error(err);
        }
      } else if (!visitorLog) {
        try {
          await visitorsClient
            .createVisitor(params)
            .then((res) => {
              setApiError(null);
              setVisitorLog(res);
              resultVisitorData = res;
            })
            .catch((err) => {
              setApiError(err.data.message);
            });
        } catch (err) {
          console.error(err);
        }
      }
      if (resultVisitorData && resultVisitorData.legalDocumentsRequired) {
        visitorsClient
          .getLegalDocsSigning({ offices: [officeId], visitorLogs: [resultVisitorData.id], status: 'unsigned' })
          .then((list) => {
            if (list.items.length) {
              let lastDoc = list.items[0];
              list.items.forEach((doc) => {
                if (new Date(doc.document.updatedAt).getTime() > new Date(lastDoc.document.updatedAt).getTime()) {
                  lastDoc = doc;
                }
              });
              setDocumentForSign(lastDoc);
            }
          });
      }
      if (formik.isSubmitting) {
        setLoading(false);
      }
    },
  });

  const handleChangeOfficesSelector = (e) => {
    const value = e.target.value;
    setOfficeId(value);
    localStorage.setItem('GUEST::OFFICE_ID', value);
    formik.setFieldValue('office', value);
    formik.setFieldValue('visitorType', '');
    formik.setFieldValue('visitorPurpose', '');
    try {
      visitorsClient.getSettingsVisitors(value).then(({ items }) => {
        setTypesList(items.filter((el) => el.kind === 'type' && el.office === value && el.showOnKiosk));
        setPurposeList(items.filter((el) => el.kind === 'purpose' && el.office === value && el.showOnKiosk));
      });
    } catch (err) {
      console.error(err);
    }
    if (visitorLog) {
      setEditedFields(true);
    }
  };

  const handleChangeType = (e) => {
    formik.setFieldValue('visitorType', e.target.value);
    const findType = typesList.find((el) => el.id === e.target.value);
    if (findType) {
      formik.setFieldValue('legalDocumentsRequired', findType.legalDocsRequired);
    }
    if (visitorLog) {
      setEditedFields(true);
    }
  };

  const handleChangeFormik = (e) => {
    formik.handleChange(e);
    if (visitorLog) {
      setEditedFields(true);
    }
  };

  const hasError = (field) => Boolean(formik.touched[field] && formik.errors[field]);
  const helperTextFor = (field, defaultText) => (hasError(field) ? formik.errors[field] : defaultText);

  return (
    <TransitionGroup style={{ height: '100%' }}>
      <CSSTransition key={step} classNames={transitionClass} timeout={1000}>
        <div className="form">
          {step === 0 && (
            <CardLayout
              title="guest.email_title"
              buttonText="next_btn"
              onClick={nextCard}
              onBack={handelBackButton}
              disabled={formik.values.email.trim() === '' || hasError('email')}
            >
              <div className="form_field">
                <input
                  className={`form_input ${hasError('email') ? 'error' : ''}`}
                  name="email"
                  placeholder={t('guest.email_label')}
                  value={formik.values.email.trim()}
                  onChange={handleChangeFormik}
                  onBlur={formik.handleBlur}
                />
                {hasError('email') && <div className="error_text">{helperTextFor('email')}</div>}
                <div className="form_note">
                  <Trans i18nKey="guest.email_note" />
                </div>
              </div>
            </CardLayout>
          )}
          {step === 1 && (
            <CSSTransition in={true} timeout={3000} classNames="right-to-left">
              <CardLayout
                title="guest.name_title"
                buttonText="next_btn"
                onClick={nextCard}
                onBack={handelBackButton}
                disabled={formik.values.firstName.trim() === '' || formik.values.lastName.trim() === ''}
              >
                <div className="form_field">
                  <input
                    className={`form_input ${hasError('firstName') ? 'error' : ''}`}
                    name="firstName"
                    placeholder={t('guest.name_first')}
                    value={formik.values.firstName}
                    onChange={handleChangeFormik}
                    onBlur={formik.handleBlur}
                  />
                  {hasError('firstName') && <div className="error_text">{helperTextFor('firstName')}</div>}
                </div>
                <div className="form_field">
                  <input
                    className={`form_input ${hasError('lastName') ? 'error' : ''}`}
                    name="lastName"
                    placeholder={t('guest.name_last')}
                    value={formik.values.lastName}
                    onChange={handleChangeFormik}
                    onBlur={formik.handleBlur}
                  />
                  {hasError('lastName') && <div className="error_text">{helperTextFor('lastName')}</div>}
                </div>
              </CardLayout>
            </CSSTransition>
          )}
          {step === 2 && (
            <CSSTransition in={true} timeout={3000} classNames="right-to-left">
              <CardLayout
                title="guest.company_title"
                buttonText="next_btn"
                onClick={nextCard}
                onBack={handelBackButton}
                disabled={formik.values.email.trim() === '' || hasError('companyName')}
              >
                <div className="form_field">
                  <input
                    className={`form_input ${hasError('companyName') ? 'error' : ''}`}
                    name="companyName"
                    placeholder={t('guest.company_label')}
                    value={formik.values.companyName.trim()}
                    onChange={handleChangeFormik}
                    onBlur={formik.handleBlur}
                  />
                  {hasError('companyName') && <div className="error_text">{helperTextFor('companyName')}</div>}
                </div>
              </CardLayout>
            </CSSTransition>
          )}
          {step === 3 && (
            <CardLayout
              title="guest.selects_title"
              buttonText={'next_btn'}
              onBack={handelBackButton}
              onClick={nextCard}
              disabled={
                formik.values.visitorPurpose === '' ||
                formik.values.visitorType === '' ||
                formik.values.office === '' ||
                hasError('visitorPurpose') ||
                hasError('visitorType') ||
                hasError('office')
              }
            >
              <div className="select">
                <label className="select_label">
                  <Trans i18nKey={'guest.select_office_label'} />{' '}
                </label>
                <select
                  name="office"
                  value={formik.values.office}
                  className="select_field select_office"
                  onChange={handleChangeOfficesSelector}
                >
                  {offices.map((office) => (
                    <option value={office._id} key={office._id}>
                      {office.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="select">
                <label className="select_label">
                  <Trans i18nKey={'guest.select_types_label'} />
                </label>
                <select
                  name="visitorType"
                  className="select_field select_types"
                  required
                  value={formik.values.visitorType}
                  onChange={handleChangeType}
                >
                  <option value="" disabled selected>
                    <Trans i18nKey={'guest.select_types_placeholder'} />
                  </option>
                  {typesList.map((type) => (
                    <option value={type.id} key={type.id}>
                      {type.title}
                    </option>
                  ))}
                </select>
              </div>
              <div className="select">
                <label className="select_label">
                  <Trans i18nKey={'guest.select_purpose_label'} />{' '}
                </label>
                <select
                  name="visitorPurpose"
                  className="select_field select_purpose"
                  value={formik.values.visitorPurpose}
                  required
                  onChange={formik.handleChange}
                >
                  <option value="" disabled selected>
                    <Trans i18nKey={'guest.select_purpose_placeholder'} />
                  </option>
                  {purposeList.map((purpose) => (
                    <option value={purpose.id} key={purpose.id}>
                      {purpose.title}
                    </option>
                  ))}
                </select>
              </div>
            </CardLayout>
          )}
          {step === 4 && (
            <CardLayout
              title="guest.user_title"
              buttonText={'next_btn'}
              onBack={handelBackButton}
              onClick={nextCard}
              isSubmit
              disabled={formik.values.hosts === '' || hasError('hosts')}
            >
              <SearchUsers
                value={valueUser}
                helperText={helperTextFor('hosts')}
                onChange={(value) => formik.setFieldValue('hosts', value)}
                onBlur={formik.handleBlur}
                error={hasError('hosts')}
                users={users}
                setValueUser={setValueUser}
                placeholder={t('guest.user_label')}
                hostsList={hostsList}
              />
            </CardLayout>
          )}
          {step === 5 && (
            <ThanksCard
              user={valueUser}
              onClick={nextCard}
              onBack={handelBackButton}
              text="guest.thanks_text"
              error={apiError}
              loading={loading}
            />
          )}
          {step === 6 && (
            <CardLayout title="guest.document_title" buttonText={'next_btn'} onClick={nextCard}>
              <div className="document_title">{documentContent.title}</div>
              <div className="document_text" dangerouslySetInnerHTML={{ __html: documentContent.text }}></div>
            </CardLayout>
          )}
          {step === 7 && (
            <CardLayout
              title="guest.signature_title"
              buttonText={'next_btn'}
              onClick={nextCard}
              disabled={!signImageDataUrl || isDisabledSignButton}
            >
              <SignatureCard onSubmit={setSignImageDataUrl} setDisabledSignButton={setDisabledSignButton} />
            </CardLayout>
          )}
          {step === 8 && (
            <CardLayout title="" buttonText={'next_btn'} onClick={nextCard}>
              <div className="wifi">
                <div className="wifi_icon">
                  <Wifi />
                </div>
                <div className="wifi_title">
                  <Trans i18nKey="guest.wifi_title" />
                </div>
                <div className="wifi__info">
                  <div className="wifi_list">
                    <div className="wifi_label">
                      <Trans i18nKey="guest.wifi_network" />
                    </div>

                    <div className="wifi_value">
                      {settingsWifi?.ssid || <Trans i18nKey="guest.wifi_network_value" />}
                    </div>
                  </div>
                  <div className="wifi_list">
                    <div className="wifi_label">
                      <Trans i18nKey="guest.wifi_password" />
                    </div>
                    <div className="wifi_value wifi_password">
                      {settingsWifi?.password || <Trans i18nKey="guest.wifi_password_value" />}
                    </div>
                  </div>
                </div>
              </div>
            </CardLayout>
          )}
        </div>
      </CSSTransition>
    </TransitionGroup>
  );
};

export default VisitorPage;
